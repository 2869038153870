<template>
  <b-card>
    <h3 class="mb-50 fw-700">
      {{ header }}
    </h3>
    <b-form ref="form">
      <b-row class="mx-0">
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nhập vào số vé"
            label-for="ticket-num"
          >
            <b-form-input
              id="ticket-num"
              v-model="ticketNumber"
              v-remove-non-numeric-chars.allNumber
              :formatter="trimAllInput"
              placeholder="Nhập số vé..."
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Chọn Hệ thống đặt vé"
            label-for="ticket-source"
          >
            <v-select
              v-model="ticketSource"
              input-id="ticket-source"
              :options="sourceOptions"
              :reduce="val => val.value"
              :searchable="false"
              :filterable="false"
              :clearable="false"
              label="name"
              placeholder="Vui lòng chọn"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Chọn loại vé"
            label-for="ticket-type"
          >
            <v-select
              v-model="ticketType"
              input-id="ticket-type"
              :searchable="false"
              :filterable="false"
              :clearable="false"
              :options="['TKT', 'EMD']"
              label="text"
              placeholder="Vui lòng chọn"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div class="d-flex-center gap-2">
      <b-button
        variant="gradient"
        pill
        class="px-3"
        :disabled="disableSearch"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="handleOpenViewEticketModal"
      >
        {{ $t('reservation.find') }}
      </b-button>
    </div>

    <ModalViewEticket
      v-if="ticketData"
      :ticket-data.sync="ticketData"
      :ticket-source="ticketSource"
      :is-out-ticket="true"
      :is-readonly="true"
    />

    <ModalShowText
      :text="ticketText"
      :id-modal="source"
      :title-modal="'Xem số vé'"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'
import {
  ref, watch, computed,
  nextTick,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import ModalViewEticket from '@/views/apps/reservation/reservation-modify/components/detail/eticket-details/ModalViewEticket.vue'

import { trimAllInput } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BCol,
    BRow,

    ModalViewEticket,
    ModalShowText: () => import('./ModalShowText.vue'),
    vSelect,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const ticketNumber = ref(null)
    const ticketSource = ref(props?.source || null)
    const ticketType = ref('TKT')

    const disableSearch = ref(true)

    watch([
      () => ticketNumber.value,
      () => ticketSource.value,
      () => ticketType.value,
    ], ([number, source, type]) => {
      if (number && source && type) {
        disableSearch.value = false
      } else {
        disableSearch.value = true
      }
    })

    const ticketData = ref(null)
    const ticketText = ref(null)

    function handleOpenViewEticketModal() {
      const payload = {
        number: ticketNumber.value,
        ticketType: ticketType.value,
      }

      ticketData.value = payload

      nextTick(async () => {
        await new Promise(resolve => setTimeout(resolve, 200))
        this.$bvModal.show('modal-modify-view-eticket-details')
      })
    }

    const sourceOptions = computed(() => [
      {
        name: 'Vietnam Airlines (VN1A)',
        value: 'VN1A',
        sourceAvail: 'VN1A',
      },
      {
        name: 'VN1A Miền Trung (VN1A_MT)',
        value: 'VN1A_MT',
        sourceAvail: 'VN1A',
      },
      {
        name: 'Bamboo Airways (QH)',
        value: 'QH',
        sourceAvail: 'QH',
      },
      {
        name: 'Travelport (1G)',
        value: '1G',
        sourceAvail: '1G',
      },
    ])

    return {
      ticketData,
      ticketNumber,
      ticketSource,
      ticketType,
      disableSearch,
      trimAllInput,
      handleOpenViewEticketModal,
      sourceOptions,
      ticketText,
    }
  },
}
</script>
